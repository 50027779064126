import React, { useState, useEffect } from "react";
import "react-phone-number-input/style.css";
import logoIcon from "../../assets/images/logo/ov-traveller-logo-new.webp";
import googleIcon from "../../assets/images/socialMediaIcons/R-google.webp";
import emailIcon from "../../assets/images/socialMediaIcons/message.png";
import facebookIcon from "../../assets/images/socialMediaIcons/R-fb.webp";
import appleIcon from "../../assets/images/socialMediaIcons/apple.webp";
import TextField from "@material-ui/core/TextField";
import { useGoogleLogin } from "@react-oauth/google";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import AppleSignin from 'react-apple-signin-auth';
import { isMobileOnly, isTablet, isDesktop, isIOS, isAndroid, isMobile } from 'react-device-detect';
import detectBrowserLanguage from 'detect-browser-language'
import FormLabel from "@material-ui/core/FormLabel";
import { DateRangePicker } from "rsuite";
import PhoneInput from 'react-phone-number-input'
import { parsePhoneNumber, getCountryCallingCode } from 'react-phone-number-input'

const formatDate = (dateString) => {
	const date = new Date(dateString);
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() is zero-based
	const day = String(date.getDate()).padStart(2, '0');

	return `${year}-${month}-${day}`;
};

function LoginPopupForm({ closePopup, messageStatic }) {
	const [languageStaticContent, setLanguageStaticContent] = useState(require(`../../assets/json/lang/en.json`));

	const [showPopup, setShowPopup] = useState(false);
	const [email, setEmail] = useState("");
	const [emailError, setEmailError] = useState(false);
	const emailRegexPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

	const todayDate = new Date()
	const currentDate = new Date()
	const new_arrival_date = currentDate.setDate(currentDate.getDate() + 4);
	const [nights, setnights] = useState(4);
	const [selectedDate, setSelectedDate] = React.useState([todayDate, new Date(new_arrival_date)]);

	const [arrivalDate, setArrivalDate] = useState(formatDate(selectedDate[0]));
	const [departureDate, setDepartureDate] = useState(formatDate(selectedDate[1]));

	const [SubmitButton, setSubmitButton] = useState(languageStaticContent.Continue);
	const [loginFormSend, setLoginFormSend] = useState(false);

	const [clientCountry, setClientCountry] = useState(localStorage.getItem('country'))
	const [loginSuccess, setLoginSuccess] = useState(false)

	const [showContinueEmailDates, setShowContinueEmailDates] = useState(false)
	const [telephone, setTelephone] = useState("");
	const [otpNumber, setOtpVal] = useState('');
	const [isSendOtp, setisSendOtp] = useState(false)
	//init 
	useEffect(() => {
		localStorage.setItem('formIsopened', true);
		const fetchJsonFile = () => {
			try {
				const browserLanguage = detectBrowserLanguage();
				const jsonData = require(`../../assets/json/lang/${browserLanguage}.json`);
				setLanguageStaticContent(jsonData)
			} catch (error) {
				console.error(error);
			}
		};

		fetchJsonFile();
		console.log(clientCountry)
		return () => {
			localStorage.setItem('formIsopened', false);
		};

	}, []);
	//init end


	//date change
	const handleDateChange = (date) => {

		const startDate = date[0];
		const endDate = date[1];

		if (startDate && endDate) {
			const timeDifference = endDate.getTime() - startDate.getTime();
			const numberOfNights = Math.ceil(timeDifference / (1000 * 3600 * 24));
			setnights(numberOfNights);

			setArrivalDate(formatDate(startDate))
			setDepartureDate(formatDate(endDate));

			localStorage.setItem("arrival", formatDate(startDate));
			localStorage.setItem("departure", formatDate(endDate));
			setSelectedDate([startDate, endDate])
		}
	};
	//date change end


	//email change
	const emailChange = (event) => {
		const newEmail = event.target.value;
		setEmail(newEmail);
		setEmailError(false);
	};
	//email change end

	//socila media login functions
	const login = useGoogleLogin({
		onSuccess: (codeResponse) => {
			fetch(
				"https://www.googleapis.com/oauth2/v3/userinfo?access_token=" +
				codeResponse.access_token
			)
				.then((response) => {
					return response.json();
				})
				.then((data) => {
					if (typeof data.email !== "undefined") {

						const now = new Date();

						// 'item' is an object which contains the original value
						// as well as the time when it's supposed to expire
						const item = {
							value: data.email,
							expiry: now.getTime() + (60 * 1000), //60 * 1000 //24 * 60 * 60 * 1000
						};
						localStorage.setItem("email", JSON.stringify(item));
						// localStorage.setItem("email", );

						localStorage.setItem("arrival", arrivalDate);
						localStorage.setItem("departure", departureDate);
						setEmail(data.email);
						setEmailError(false);
						setShowPopup(true);
						closePopup(false, true);
						emailSaveToDb();
						window.location.reload();
					} else {
						setEmailError(true);
					}
				});

		},
		onError: (errorResponse) => {
			console.log(errorResponse)
		}
	});

	const responseFacebook = (response) => {
		try {
			if (typeof response.email !== "undefined") {
				setEmail(response.email);
				setEmailError(false);
				localStorage.setItem("email", response.email);
				localStorage.setItem("arrival", arrivalDate);
				localStorage.setItem("departure", departureDate);
				setShowPopup(true);
				closePopup(false, true);
				emailSaveToDb();
				window.location.reload();
			} else {
				throw new Error("Email is undefined in the Facebook response.");
			}
		} catch (error) {
			console.error("Error during Facebook login:", error.message);
		}
	};

	const handleAppleResponse = (response) => {
		console.log(response);
		// Handle the response here
		// You may want to save the user's information or authenticate with your backend
	};
	//social media login functions end

	const handleOTPnumber = (event) => {
		const value = event.target ? event.target.value : event;
		setOtpVal(value);
	};

	//form submit to system
	const handleOnclick = () => {
		setLoginFormSend(true)
		if (validateEmail(email)) {
			setShowPopup(true);
			if (isSendOtp) {
				setSubmitButton("Checking OTP...");
				verifyOtp().then(() => {
					emailSaveToDb().then(() => {

						const now = new Date();

						// 'item' is an object which contains the original value
						// as well as the time when it's supposed to expire
						const item = {
							value: email,
							expiry: now.getTime() + (24 * 60 * 60 * 1000), //60 * 1000 //24 * 60 * 60 * 1000
						};
						localStorage.setItem("email", JSON.stringify(item));

						localStorage.setItem("arrival", arrivalDate);
						localStorage.setItem("departure", departureDate);
						setSubmitButton(languageStaticContent.Continue);
						setLoginFormSend(false)
						closePopup(false, true);
						setLoginSuccess(true);
						window.location.reload();
					});
				})
			} else {
				sendOtpCode()
			}

		} else {
			setLoginFormSend(false)
			setEmailError(true);
			setSubmitButton(languageStaticContent.Continue);
			closePopup(true, false);
		}
	}

	const validateEmail = (email) => {
		return emailRegexPattern.test(email);
	};

	const sendOtpCode = () => {
		return new Promise((resolve, reject) => {
			setSubmitButton("Sending OTP...");
			fetch("https://www.viluxurholidays.com/srm/modules/api/V5/sendOtp.php?phoneNo=" + telephone, {
				method: "GET",
			})
				.then((response) => response.json())
				.then((data) => {
					setisSendOtp(true)
					setSubmitButton("Enter OTP");
					resolve()
				})
				.catch((error) => {
					console.log("not work");
					console.log(error);
					// reject(error)
				});

		});
	}

	const verifyOtp = () => {
		return new Promise((resolve, reject) => {
			fetch("https://www.viluxurholidays.com/srm/modules/api/V5/verifyOTP.php?otpNumber=" + otpNumber + "&phoneNo=" + telephone, {
				method: "GET",
			})
				.then((response) => response.json())
				.then((data) => {
					console.log(data);
					if (data.valid) {
						resolve()
					} else {
						reject("error")
					}
				})
		})
	}

	const emailSaveToDb = () => {
		return new Promise((resolve, reject) => {
			const apiUrl = arrivalDate
				? "https://www.discoverymaldives.com/experience/thank-you.php"
				: 'https://www.discoverymaldives.com/experience/V3/saveEmail.php';

			let device = '';
			let os = '';

			if (isMobileOnly) {
				device = 'mobile';
			} else if (isTablet) {
				device = 'tablet';
			} else if (isDesktop) {
				device = 'pc';
			}

			if (isIOS) {
				os = 'iOS';
			} else if (isAndroid) {
				os = 'Android';
			}

			const browserLanguage = detectBrowserLanguage();
			const data = {
				email: email,
				fldCustomerEMail: email,
				fldCustomerFormType: 38,
				fldCustomerFormMessage1: "Login Form " + (messageStatic) ? messageStatic : "",
				fldcustomerFax: 0,
				ArrivalDate: arrivalDate,
				departureDate: departureDate,
				fldCustomerFormNights: nights,
				keyword: localStorage.getItem("keyword"),
				first_landing_page: localStorage.getItem("first_landing_page"),
				country: localStorage.getItem('country'),
				campaign_id: localStorage.getItem('camid'),
				fldAdVisitorID: localStorage.getItem("fldAdVisitorID"),
				adfrom: localStorage.getItem("adfrom"),
				code: localStorage.getItem("code"),
				agp: localStorage.getItem("agp"),
				camid: localStorage.getItem("camid"),
				device: device,
				os: os,
				mobileCheck: isMobile,
				history: sessionStorage.getItem('pageData'),
				clientBrowserLanguage: browserLanguage
			};

			fetch(apiUrl, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(data) // Convert the data to JSON format
			})
				.then(response => {
					if (response.ok) {
						return response.json();
					} else {
						reject(new Error('Failed to send data'));
					}
				})
				.then(responseData => {
					console.log('Data sent successfully');
					localStorage.setItem('fmd', responseData.fmd); // Use parsed response data
					resolve();
				})
				.catch(error => {
					console.error('Error:', error);
					reject(error);
				});
		}
		)
	}

	//form submit to system end

	// const handleClosePopUp = () => {
	// 	closePopup(false, false);
	// };

	const disablePastDates = (date) => {
		const today = new Date();
		today.setHours(0, 0, 0, 0); // Clear the time part
		return date < today;
	};


	const showEmailAndDates = () => {
		setShowContinueEmailDates(true)
	}

	return (
		<div className={`login-popup-container ${showPopup ? "full-screen" : ""} ${clientCountry == "AE" && !loginSuccess ? "blur-header" : ""}`}>
			<div className="login-popup-body">
				<div className="first-content-text">
					<p className="ov-description primary text-bold" style={{
						padding: "0px 40px",
						fontSize: "20px"
					}}>
						{languageStaticContent.Sig_in_wit_you_tra} {languageStaticContent.to_unl_and_cus_res}
					</p>
				</div>

				<div className="login-form-content">
					<div className="login-email">
						<FormLabel
							className="login-form-label"
							component="legend"
							style={{ textAlign: "left" }}
						>
							{languageStaticContent.Travel_Dates}
						</FormLabel>
						<DateRangePicker
							showOneCalendar
							onChange={handleDateChange}
							value={selectedDate}
							className="login-form-datepicker"
							disabledDate={disablePastDates}
						/>
					</div>

					<div className="login-email email-wrapper">
						<TextField
							required
							placeholder="Email"
							label={languageStaticContent.Your_Email}
							name="fldCustomerEMail"
							key="fldCustomerEMail"
							id="email"
							onChange={emailChange}
							error={emailError}
							helperText={emailError ? languageStaticContent.Invalid_email_format : ""}

						/>
					</div>
					<div className="login-telephone">
						<div className="tel-nomber-sec">
							<div className="consulting-input-filed-prepend w-100">
								<PhoneInput className={`mobilenumberInput w-100 input-font-new`}
									international
									countryCallingCodeEditable={false}
									value={telephone}
									onChange={setTelephone}
									defaultCountry={localStorage.getItem('country')} />
							</div>
						</div>
					</div>

					{isSendOtp &&
						<div className="login-otp" >
							<TextField
								required
								placeholder="OTP"
								label="OTP"
								name="otp"
								key="otp"
								id="otp"
								onChange={handleOTPnumber}
							/>
						</div>
					}


					<div className="d-grid gap-2 mt-4">
						<button
							type="submit"
							className="ov_button primary-button"
							onClick={handleOnclick}
							id="ov-login-desktop-email"
						>
							{SubmitButton}
						</button>
					</div>

					<div>
						<p className="ov-label primary  ov-lh-small mt-3">
							{languageStaticContent.Dis_By_pro_you_agr}
						</p>
					</div>

					<div className="r-login-media-icons">
						<span style={{ paddingLeft: "0px" }}>
							{/* <img
							src={rPilotIcon}
							alt="Icon"
							style={{ width: "22px", height: "auto", marginTop: "-4px" }}
						/> */}
							<i className="fa fa-star" aria-hidden="true"></i>

							<span className="r-text">5.0</span>
						</span>

						<span style={{ paddingLeft: "20px" }}>
							{/* <img
							src={rGoogleIcon}
							alt="Icon"
							style={{ width: "20px", height: "auto" }}
						/> */}
							<i className="fa fa-google" aria-hidden="true"></i>
							<span className="r-text">4.9</span>
						</span>
						<span style={{ paddingLeft: "20px" }}>
							{/* <img
							src={rFbIcon}
							alt="Icon"
							style={{ width: "20px", height: "auto" }}
						/> */}
							<i className="fa fa-facebook-square" aria-hidden="true"></i>
							<span className="r-text">4.9</span>
						</span>

						<span style={{ paddingLeft: "20px" }}>
							{/* <img
							src={rAdvisorIcon}
							alt="Icon"
							style={{ width: "22px", height: "auto" }}
						/> */}
							<i className="fa fa-tripadvisor" aria-hidden="true"></i>
							<span className="r-text">5.0</span>
						</span>
					</div>
				</div>
			</div>
		</div>
	);
}

export default LoginPopupForm;
