import React, { Component, lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import { Transition, TransitionGroup } from "react-transition-group";
import TrackingComponent from '../components/TrackingComponent';

import { ProductCard } from '../components/Cards/productCard';

//main pages
const Homenew = lazy(() => import('../homeNew'));
const SignUpHomePage = lazy(() => import('../signUpHome'));
const MaldivesResorts = lazy(() => import('../components/maldives/MaldivesResorts'));
const ResortInner = lazy(() => import('../components/Resort/ResortInner'));
const PrivacyPolicy = lazy(() => import('../privacypolicy'));
const BookingAndRefundPolicies = lazy(() => import('../BookingAndRefundPolicies'));
const AboutUssNew = lazy(() => import('../AboutUssNew'));
const specialOffers = lazy(() => import('../components/offer/offers'));
const OfferInner = lazy(() => import('../components/offer/offerInner'));

//tailerd holiday sections
const privateIslands = lazy(() => import('../components/maldives/privateIslands'));
const privateIslandsInner = lazy(() => import('../components/maldives/privateIslandInner'));
const privateYaght = lazy(() => import('../components/maldives/privateYaght'));
const wedding = lazy(() => import('../components/maldives/wedding'));
const privateIslandsInner2 = lazy(() => import('../components/maldives/privateislandinner2'));
const privateYaghtInner = lazy(() => import('../components/maldives/privateYaghtinner'));
const privateYaghtInner2 = lazy(() => import('../components/maldives/privateYaghtinner2'));
const Pullman = lazy(() => import('../components/maldives/pullman'));
const Cornad = lazy(() => import('../components/maldives/cornad'));
const underWaterVillas = lazy(() => import('../components/maldives/underWaterVillas'));

//blogs section
const Blog = lazy(() => import('../components/maldives/blog'));
const BlogInnerNew = lazy(() => import('../components/Blogs/BlogInnerNew'));

const NotFound = lazy(() => import('../NotFound'));
const Destination = lazy(() => import('../Destination'));
const Maldives = lazy(() => import('../Maldives'));

const TermsConditions = lazy(() => import('../Terms&Conditions'));
const PaymentCancelationagreement = lazy(() => import('../PaymentCancelationAgreement'));

const ContactUs = lazy(() => import('../ContactUs'));
const ReferalProgramme = lazy(() => import('../components/ReferalProgramme'));
const maldivesHolidays = lazy(() => import('../components/maldives/holidays'));
const hardrock = lazy(() => import('../components/maldives/hardrock'));

const villas = lazy(() => import('../components/maldives/villas'));
const privetevillas = lazy(() => import('../components/maldives/Privetevillas'));

const familyVillas = lazy(() => import('../components/maldives/familyVillas'));
const beachVillas = lazy(() => import('../components/maldives/beachVillas'));
const waterVillas = lazy(() => import('../components/maldives/waterVillas'));



const BlogInner = lazy(() => import('../components/maldives/blogInner'));
const BlogWomen = lazy(() => import('../components/maldives/blogWomen'));
const EidBlog = lazy(() => import('../components/maldives/EidBlog'));
const BestOffers = lazy(() => import('../components/maldives/BestOffers'));
const GirlBlog = lazy(() => import('../components/maldives/GirlBlog'));
const GrandBlog = lazy(() => import('../components/maldives/GrandBlog'));
const EidBlogArabic = lazy(() => import('../components/maldives/EidBlogArabic'));
const BitcoinBlog = lazy(() => import('../components/maldives/bitcoinBlog'));
const BudgetThankyou = lazy(() => import('../components/maldives/budgetThankyou'));
const ParantBlog = lazy(() => import('../components/Blogs/ParentBlog/ParantBlog'));

const AboutUssNewInner = lazy(() => import('../AboutUssNewInner'));

const consciousBlog = lazy(() => import('../components/maldives/consciousBLog'));
const desiBLog = lazy(() => import('../components/maldives/DesiBlog'));
const Cryptoblog = lazy(() => import('../components/Blogs/Cryptoblog'));
const kidsBlog = lazy(() => import('../components/maldives/kidsBlog'));
const soloBlog = lazy(() => import('../components/maldives/soloBlog'));
const SaudiBlogNew = lazy(() => import('../components/maldives/SaudiBlogNew'));
const ReferFriend = lazy(() => import('../components/maldives/referAFriendBlog'));
const jointBlog = lazy(() => import('../components/maldives/jointBlog'));
const reapBlog = lazy(() => import('../components/maldives/reapBlog'));
const easterBlog = lazy(() => import('../components/maldives/easterBlog'));
const repeaterblog = lazy(() => import('../components/maldives/Repeaterblog'));
const standerdBlog = lazy(() => import('../components/maldives/standerdBlog'));
const uaeNationalBlog = lazy(() => import('../components/maldives/UaeNational'));
const critsmasBlog = lazy(() => import('../components/maldives/critsmasBlog'));
const angsanaBlog = lazy(() => import('../components/maldives/blogAngsana'));
const cryptoPage = lazy(() => import('../components/maldives/cryptoPage'));
const flightBlog = lazy(() => import('../components/maldives/flightBlog'));
const consciousParadise = lazy(() => import('../components/maldives/consciousParadise'));
const blogEaster = lazy(() => import('../components/maldives/blogEaster'));
const EidOfferBlog = lazy(() => import('../components/Blogs/EidOfferBlog'));
const patinaSummerHoliday2023 = lazy(() => import('../components/Blogs/patina-maldives/patinaSummerHoliday2023'));
const FestiveSeason = lazy(() => import('../components/Blogs/FestiveSeason'));
const CelebrityBlogs = lazy(() => import('../components/Blogs/CelebrityBlogs'));
const SavingsBlogs = lazy(() => import('../components/Blogs/SavingsBlogs'));
const UnderWaterVilla = lazy(() => import('../components/Blogs/UnderWaterVilla'));



// class define all routes which will be included to App for redirections and Links between all components
// in case we add a new component we should add its Route definition here (inside Switch ) to be accessible through url and navigation link
class Routes extends Component {

  constructor(props) {
    super(props);
  }
  render() {

    return (
      <Route
        render={({ location }) => {
          const { key } = location;

          return (
            <TransitionGroup component={null}>
              <Transition
                key={key}
                appear={true}
                timeout={{ enter: 0, exit: 0 }}
                unmountOnExit
              >
                <Suspense fallback={<></>}>

                  <TrackingComponent />
                  <Switch basename="/" location={location}>
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/home`} //this page like maintence mode, customers need to sign up or validate before checking the website
                      render={(props) => (
                        <SignUpHomePage />
                      )}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/`}
                      component={SignUpHomePage}
                    />
                    {/* <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/card-ui`}
                      component={ProductCard}
                    /> */}
                    <Route
                      path={`${process.env.PUBLIC_URL}/about-us`}
                      component={AboutUssNew}
                    />

                    {/* for blog section */}
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/travel-blogs`}
                      component={Blog}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/travel-blog-maldives/vow-renewal-in-maldives-luxury-resorts`}
                      component={BlogInner}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/travel-blog-maldives/it-s-all-about-the-right-time`}
                      component={jointBlog}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/travel-blog-maldives/who-does-maldives-best`}
                      component={flightBlog}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/travel-blog-maldives/love-in-the-maldives?`}
                      component={repeaterblog}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/travel-blog-maldives/love-in-the-maldives`}
                      component={reapBlog}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/travel-blog-maldives/easter-blog`}
                      component={easterBlog}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/travel-blog-maldives/the-best-ways-to-explore-the-maldives`}
                      component={standerdBlog}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/travel-blog-maldives/are-you-a-conscious-traveller`}
                      component={consciousBlog}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/travel-blog-maldives/are-you-looking-for-a-mid-week-break`}
                      component={blogEaster}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/pay-with-cryptocurrency`}
                      component={cryptoPage}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/conscious-paradise`}
                      component={consciousParadise}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/travel-blog-maldives/what-do-you-explore`}
                      component={angsanaBlog}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/travel-blog-maldives/desi-love-in-the-maldives`}
                      component={desiBLog}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/travel-blog-maldives/maldives-early-bird-eid-offers`}
                      component={EidBlog}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/travel-blog-maldives/maldives-eid-offers`}
                      component={EidOfferBlog}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/travel-blog-maldives/maldives-early-bird-eid-offers-arabic`}
                      component={EidBlogArabic}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/travel-blog-maldives/women-inspiring-today-for-a-better-tomorrow`}
                      component={BlogWomen}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/travel-blog-maldives/traveling-with-kids-to-the-maldives`}
                      component={kidsBlog}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/travel-blog-maldives/take-the-solo-trip`}
                      component={soloBlog}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/travel-blog-maldives/celebrate-saudi-national-holiday-in-the-maldives`}
                      component={SaudiBlogNew}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/travel-blog-maldives/refer-a-friend-and-earn-from-home`}
                      component={ReferFriend}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/travel-blog-maldives/paying-with-bitcoin-for-your-luxury-holiday`}
                      component={BitcoinBlog}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/travel-blog-maldives/take-a-girls-trip`}
                      component={GirlBlog}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/travel-blog-maldives/celebrate-leaving-the-working-years-behind-you`}
                      component={GrandBlog}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/travel-blog-maldives/are-you-a-procrastinator`}
                      component={critsmasBlog}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/travel-blog-maldives/grand-parents`}
                      component={ParantBlog}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/travel-blog-maldives/paying-with-cryptocurrency-for-your-luxury-holiday`}
                      component={Cryptoblog}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/travel-blog-maldives/what-are-you-doing-this-long-weekend`}
                      component={uaeNationalBlog}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/maldives-resort-packages`}
                      component={BestOffers}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/maldives-budgets/thank-you`}
                      component={BudgetThankyou}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/luxury-travel-maldives`}
                      component={Maldives}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/luxury-travel-:seoCoutryUrl`}
                      component={Destination}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/:destination-luxury-resorts`}
                      component={MaldivesResorts}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/:maldives-villas`}
                      component={villas}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/:maldives-villas/private-villas`}
                      component={privetevillas}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/:maldives-villas/under-water-villas`}
                      component={underWaterVillas}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/:maldives-villas/under-water-villas/pullman-maldives`}
                      component={Pullman}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/:maldives-villas/under-water-villas/conrad-maldives`}
                      component={Cornad}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/:maldives-villas/family-villas`}
                      component={familyVillas}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/:maldives-villas/beach-villas`}
                      component={beachVillas}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/:maldives-villas/water-villas`}
                      component={waterVillas}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/special-offers`}
                      component={specialOffers}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/:maldives-holidays`}
                      component={maldivesHolidays}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/:maldives-giveaway`}
                      component={hardrock}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/special-offers/:offerInnerSlug`}
                      component={OfferInner}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/:maldives-holidays/private-islands`}
                      component={privateIslands}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/:maldives-holidays/private-yacht`}
                      component={privateYaght}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/:maldives-holidays/maldives-destination-wedding`}
                      component={wedding}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/:maldives-holidays/private-islands/waldorf-astoria-ithaafushi`}
                      component={privateIslandsInner}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/:maldives-holidays/private-islands/cheval-blanc-randheli`}
                      component={privateIslandsInner2}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/:maldives-holidays/private-yacht/maldives-yacht-soneva-in-aqua`}
                      component={privateYaghtInner}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/:maldives-holidays/private-yacht/maldives-yacht-norma-st-regis`}
                      component={privateYaghtInner2}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/:destination-luxury-resorts/:resortSeoUrl`}
                      component={ResortInner}
                    />
                    {/* <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/:maldives-budgets/:resortOfferSeoUrl`}
                    component={OfferInner}
                  /> */}
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/privacy-policy`}
                      component={PrivacyPolicy}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/terms-conditions`}
                      component={TermsConditions}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/payment-cancelation`}
                      component={PaymentCancelationagreement}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/booking-refund-policies`}
                      component={BookingAndRefundPolicies}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/contact-us`}
                      component={ContactUs}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/refer-and-earn-program`}
                      component={ReferalProgramme}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/about-us-new`}
                      component={AboutUssNew}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/message-from-the-founder`}
                      component={AboutUssNewInner}
                    />

                    {/* Blogs Start*/}
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/travel-blog-maldives/discover-maldives-adventure-activities`}
                      component={patinaSummerHoliday2023}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/festive-season-offers`}
                      component={FestiveSeason}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/celebrity-vacation-spots`}
                      component={CelebrityBlogs}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/best-maldives-deals`}
                      component={SavingsBlogs}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/maldives-underwater-experiences`}
                      component={UnderWaterVilla}
                    />
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/travel-blogs/:resortSeoUrl`}
                      component={BlogInnerNew}
                    />

                    {/* Blogs End */}

                    <Route component={NotFound} />
                  </Switch>
                </Suspense>
              </Transition>
            </TransitionGroup>
          );
        }}
      />
    );
  }
}

export default Routes;
